.foodie-navbar {
    display: flex;
    width: 100%;
    background-color: #efefef;
}

.nav-search-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.nav-search {
    display: flex;
    align-items: flex-end;
    background-color: white;
    border-radius: 0 10px 0 0;
    padding: 15px 0 0 0;
}

.nav-search-input {
    width: 100%;
    background-color: #efefef;
    padding: 15px;
    margin: 0 10px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-search-input input {
    width: 80%;
    border: none;
    outline: none;
    padding: 10px;
    border-radius: 15px;
    font-family: 'Merriweather', serif;
}

.nav-search-input input:focus {
    box-shadow: 0px 0px 10px rgb(81, 81, 81, .3);
    -webkit-appearance: none;
}

.nav-links {
    width: 25%;
    float: right;
    margin-right: 50px;
    background-color: #f9f9f9;
    border-radius: 0 0 5px 5px;
    display: none;
    /* box-shadow: -2px 2px 0px rgb(81, 81, 81, .1); */
    border-left: 2px solid rgb(81, 81, 81, .05);
    border-bottom: 2px solid rgb(81, 81, 81, .05);
}

.nav-links a {
    /* color: rgb(92, 92, 92); */
    color: indianred;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Merriweather', serif;
    font-style: italic;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.nav-links a:hover {
    background-color: #efefef;
    border-radius: 0 0 5px 5px;
}

.icon {
    float: right;
    margin-right: 10px;
    font-size: 16pt;
    color: indianred;
    cursor: pointer;
}

.search {
    position: absolute;
    width: 350px;
    padding: 0 15px 15px;
    background-color: rgb(239, 239, 239, .8);
    border-radius: 0 0 5px 5px;
    right: 30%;
    display: none;
  }
  
  .search a {
      display: flex;
      flex-direction: column;
      color: indianred;
      text-decoration: none;
      font-weight: bold;
      font-family: 'Merriweather', serif;
      font-style: italic;
      padding: 10px;
      cursor: pointer;
  }
  
  .search a:hover {
      background-color: #f9f9f9;
      border-radius: 5px;
  }

  .no-results {
    text-align: center;
    display: none;
    font-family: 'Castoro', serif;
  }

@media screen and (max-width: 1320px) {
    .search {
        width: 20%;
    }
  }

@media screen and (max-width: 900px) {
    .logo {
        width: 100%;
        text-align: center;
        padding: 10px;
        border-radius: 0px;
    }

    .foodie-navbar {
        flex-direction: column;
    }

    .nav-links {
        width: 40%
    }

    .search {
        left: 10%;
        width: 80%;
    }
}