/* Regular text */
@import url('https://fonts.googleapis.com/css2?family=Castoro&family=DM+Serif+Text&family=Merriweather&family=Sawarabi+Gothic&display=swap');
/* Logo */
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Merriweather:ital,wght@0,900;1,900&display=swap');

html, body {
    font-family: sans-serif;
    margin: 0;
    background-color: #efefef;
    font-family: 'Sawarabi Gothic', sans-serif;
}

h1, h2, h3 {
    font-family: 'Castoro', serif;
}

a, a:hover {
    color: indianred;
    text-decoration: underline;
}

::selection {
    background-color: rgb(229, 134, 134);
    color: white;
}

.body {
    background-color: #efefef;
    margin-bottom: 50px;
}

.foodie-container {
    width: 60%;
    max-width: 900px;
    height: 100%;
    margin: 0 auto;
    background-color: white;
    border-radius: 0 0 10px 10px;
}

.logo {
    width: fit-content;
    padding: 50px;
    background-color: indianred;
    font-weight: bold;
    color: white;
    font-size: 36pt;
    font-family: 'Merriweather', serif;
    font-weight: 900;
    font-style: italic;
    cursor: pointer;
    border-radius: 0 0 5px 0;
    /* font-family: 'Kaushan Script', cursive; */
}

.logo a {
  color: white;
  text-decoration: none;
}

.content {
    padding: 2em;
}

.carousel {
    text-align: center;
    background-color: #efefef;
    border-radius: 10px;
    padding: 5px;
}

@media screen and (max-width: 1080px) {
    .foodie-container {
        width: 100%;
    }
}