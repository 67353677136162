

.home-card-wrapper {
    width: 96%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
    padding: 15px;
    align-items: center;
}

#hot-new-label {
   text-align: center;

}

#hot-new {
    text-align: center;
    font-size: 20px;
    
    
}

.carousel-item a {
    color: white;
    text-decoration: none;
}