.title-container {
    display: flex;
}
.title-container h1 {
    margin-bottom: -5px;
    padding: 0px;
}
.title-container span {
    color: rgba(0, 0, 0, .7);
    padding-left: 5px;
}
.title-container img {
    border-radius: 10px;
}
.review-info {
    display: flex;
}
.categories-right {
    margin: auto 0;
}
.categories-under {
    display: none;
    padding-top: 15px;

}
.review-container {
    width: 100%;
}
.review-title {
    display: flex;
    font-family: 'Merriweather', serif;
}
.user-info {
    padding: 5px 0 0 10px;
}
.review-content {
    padding: 10px;
}
.review-content h4 {
    font-family: 'Merriweather', sans-serif;
    font-size: 12pt;
    font-weight: bold;
}
.review-header {
    padding: 15px;
    border-radius: 10px;
    background-color: #f8f8f8;
    margin: 20px 0;
}

.avatar {
    width: 75px;
    height: 100px;
    border-radius: 10px;
    background-size: cover;
    opacity: 70%;
}

@media screen and (max-width: 900px) {
    .categories-right {
        display: none;
    }

    .categories-under {
        display: block;
    }

    .title-container img {
        width: 75px;
    }

    .avatar {
        width: 50px;
        height: 75px;
    }
}